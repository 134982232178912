import { Injectable } from '@angular/core';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public showMenu: any = true;
  // public baseUrl: string = "http://localhost:1000/api/";
  public baseUrl: string = "https://us-central1-aicasindia-in.cloudfunctions.net/app/api/";

  ColumnMode = ColumnMode;
  constructor(
    private afs: AngularFirestore,
    public http: HttpClient
  ) {

  }

  saveFranchiseSignupData(signup) {
    return this.afs.collection('franchises_request').doc(signup.email).set(signup);

    //});
  }

  //  login(data){
  //   return this.afs.collection('franchises').doc(data.registerNo).valueChanges();
  // }
  // editFranchises(edit){
  //   return this.afs.collection('franchises').doc(edit.registerNo).set(edit);
  // }
  // saveStudentData(data) {
  //   let fid = JSON.parse(localStorage.getItem('profile')).registerNo ;
  //   return this.afs.collection('franchises').doc(fid).collection('students').doc(data.id).set(data);
  //
  // getStudentProfile(){
  //   let fid = JSON.parse(localStorage.getItem('profile')).registerNo ;
  //   let sid =localStorage.getItem('studentId') ;
  //   return this.afs.collection('franchises').doc(fid).collection('students').doc(sid).valueChanges();
  // }
  // editStudentProfile(data){
  //   let fid = JSON.parse(localStorage.getItem('profile')).registerNo ;
  //   let sid =localStorage.getItem('studentId') ;
  //   return this.afs.collection('franchises').doc(fid).collection('students').doc(sid).set(data);
  // }
  deleteStudentProfile(id) {
    let fid = JSON.parse(localStorage.getItem('profile')).registerNo;
    return this.afs.collection('franchises').doc(fid).collection('students').doc(id).delete();
  }
  // addMaterials(data){
  //   let id = Math.random() * 10000;
  //   data.id = id.toString();
  //   let fid = JSON.parse(localStorage.getItem('profile')).registerNo ;
  //   return this.afs.collection('franchises').doc(fid).collection('materials').doc(id.toString()).set(data);
  // }
  // getMaterials(){
  //   let fid = JSON.parse(localStorage.getItem('profile')).registerNo ;
  //   return this.afs.collection('franchises').doc(fid).collection('materials').valueChanges();
  // }
  // deleteMaterials(data){

  //   let fid = JSON.parse(localStorage.getItem('profile')).registerNo ;
  //   return this.afs.collection('franchises').doc(fid).collection('materials').doc(data.id).delete();
  // }
  saveStudentInAll(data) {
    return this.afs.collection('all-students').doc(data.id + data.password).set(data);
  }
  deleteStudentInAll(data) {
    return this.afs.collection('all-students').doc(data.id + data.password).delete();
  }
  getOneStudentdata(data) {
    let fid = JSON.parse(localStorage.getItem('profile')).registerNo;
    return this.afs.collection('franchises').doc(fid).collection('students').doc(data).valueChanges();
  }

  //!Exam
  AddQuestion(question) {
    let fid = JSON.parse(localStorage.getItem('profile')).registerNo;
    console.log(fid);
    if (question.language == 'gujarati') {
      return this.afs.collection('franchises').doc(fid).collection('questions').doc('gujarati').collection('gujarati-questions').doc(question.id.toString()).set(question);
    } else {
      return this.afs.collection('franchises').doc(fid).collection('questions').doc('english').collection('english-questions').doc(question.id.toString()).set(question);
    }

  }

  //  getAllStudentExamDetail(){
  //   let fid = JSON.parse(localStorage.getItem('profile')).registerNo ;
  //   return this.afs.collection('franchises').doc(fid).collection('students').valueChanges();
  //  }
  //  getOneStudentExamDetail(){
  //     let fid = JSON.parse(localStorage.getItem('profile')).registerNo ;
  //     let sid =localStorage.getItem('studentId') ;
  //     return this.afs.collection('franchises').doc(fid).collection('students').doc(sid).valueChanges();
  //  }
  editOneStudentExamDetail(data) {
    let fid = JSON.parse(localStorage.getItem('profile')).registerNo;
    let sid = localStorage.getItem('studentId');
    return this.afs.collection('franchises').doc(fid).collection('students').doc(sid).set(data);
  }

  // //! Franchise Id 
  // getFranchiseId(){
  //   return this.afs.collection('franchises_id').doc('fid').valueChanges();
  // }
  //!student id get
  // getStudentId(){
  //   return this.afs.collection('students_id').doc('sid').valueChanges();
  // }
  //! ////////////////////////////////////////////////////////////////////////////////////
  //! Franchises api
  registerFranchises(data) {
    return this.http.post(this.baseUrl + "franchises/register", data);
  }
  loginFranchises(data) {
    return this.http.post(this.baseUrl + "franchises/login", data);
  }
  editFranchises(data) {
    return this.http.post(this.baseUrl + "franchises/update", data);
  }

  //add materials api

  addMaterials(data) {
    return this.http.post(this.baseUrl + "materials/add_materials", data);
  }
  getMaterials(data) {
    return this.http.post(this.baseUrl + "materials/get_materials", data);
  }
  deleteMaterials(data) {
    return this.http.post(this.baseUrl + "materials/delete_material", data);
  }
  //!student api
  registerStudents(data) {
    return this.http.post(this.baseUrl + "students/register", data);
  }

  getAllStudentsByFranchises(data, pageNo) {
    // console.log(franchises_id)
    return this.http.post(this.baseUrl + "students/getStudents_byFranchises/?page=" + pageNo, data);
  }

  editStudent(data) {
    return this.http.post(this.baseUrl + "students/update", data);
  }

  deleteStudent(data) {
    return this.http.post(this.baseUrl + "students/delete", data);
  }
  getStudentsByFranchiseExamTrue(data) {
    return this.http.post(this.baseUrl + "students/getStudents_byFranchises_examAttended", data);
  }
  getPracticalMark(data) {
    return this.http.post(this.baseUrl + "answers/generate_practical_mark", data);
  }
  getResult(student_id) {
    return this.http.post(this.baseUrl + "results/get_result", student_id);
  }
  updateResult(data) {
    return this.http.post(this.baseUrl + "results/update_result", data);
  }
  getAllStudentsByFranchisesWithExamDetail(data) {
    return this.http.post(this.baseUrl + "students/getStudents_byFranchises_withExamDetail", data);
  }
  //!dashboard api

  getLength(data) {
    return this.http.post(this.baseUrl + "students/get_partner_dashboard", data);
  }

  getOneStudentWithExamDetail(data) {
    return this.http.post(this.baseUrl + "students/getStudent_byFranchises_withExamDetail", data);

  }

  //!student id
  getStudentId() {
    return this.http.get(this.baseUrl + "students/get_last_student");

  }
  //!New(null) students pagination
  newStudents(data, pageNo) {
    return this.http.post(this.baseUrl + "students/pagination_getNew_students/?page=" + pageNo, data);
  }

  //!Approve students pagination
  approvedStudents(data, pageNo) {
    return this.http.post(this.baseUrl + "students/pagination_getApproved_students/?page=" + pageNo, data);
  }
  //!Disapprove students pagination
  disapprovedStudents(data, pageNo) {
    return this.http.post(this.baseUrl + "students/pagination_getDisapproved_students/?page=" + pageNo, data);
  }
  //!get single student
  getSingleStudent(data) {
    return this.http.post(this.baseUrl + "students/get_single_student", data);
  }
//!search api for new student
searchNewStudent(data){
  return this.http.post(this.baseUrl + "students/search_new_student", data);
}
//!search api for approve student
searchApproveStudent(data){
  return this.http.post(this.baseUrl + "students/search_approved_student", data);
}
//!search api for disapprove student
searchDisapproveStudent(data){
  return this.http.post(this.baseUrl + "students/search_disapproved_student", data);
}

searchTestResult(data){
  return this.http.post(this.baseUrl + "students/search_test_result", data);
}
}
